html {
  --color-background: #ffffff;
  --color-text: #000000;
  --color-text-light: lightgray;
  --color-button-start: #0557ff;
  --color-button-stop: #ff0000;
  --color-button-text: #ffffff;
}

@media (prefers-color-scheme: dark) {
  html {
    --color-background: #000213;
    --color-text: #a5a8d2;
    --color-text-light: #444766;
    --color-button-start: #0557ff;
    --color-button-stop: #ff0000;
    --color-button-text: #d8d8d8;
  }
}

body {
	margin: 0;
	padding: 0;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
  background-color: black;
  background-image: url('../public/background.jpg'); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.RandomPicker {
	display: flex;
	flex-direction: column;
  margin: auto !important;
  padding-top: 16%;
	align-items: center;
	justify-content: center;
  font-family: 'Helvetica', sans-serif;
}

.RandomPicker__choice {
  display: flex;
	align-content: center;
  
	margin: 0;
  margin-bottom: 2em;
  height: 180px;
	color: var(--color-text);
	font-size: 60px;
  font-weight: bold;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
  color: white;
  text-shadow: 4px 4px 8px black ;
}

.RandomPicker__button {
  display: block;
	padding: .8em 1.6em;
  min-width: 160px;
  
	color: var(--color-button-text);
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 2px;
  
	border: 0;
	border-radius: 4em;
	background-color: var(--color-button-start);

  user-select: none;
  cursor: pointer;
  
  transition: 
    background-color 250ms ease-in-out,
    color 150ms ease-in-out;
  
  &--stop {  
  	background-color: var(--color-button-stop);
  }
	
	&--reset {
		color: var(--color-text);
		font-size: 14px;
		text-transform: lowercase;
    background-color: inherit;
    
    &[disabled] {
      color: var(--color-text-light);
      text-decoration: line-through;
      cursor: not-allowed;
    }
	}
}

.RandomPicker__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
}

.App {
  height : 100vh !important;
  width : 100vw !important;
  overflow : hidden !important;
}

#root {
  overflow : hidden !important;
}

body {
  overflow : hidden !important;
}